//variable overrides
@import "solarized";

/*==========  Mobile First Method  ==========*/

/* Extra small devices (phones, 480px and up) */
@media (min-width: @screen-xs-min) { 

    h1 {
        font-size: 1.8em;
    }
        
    h2 {
        font-size: 1.3em;
    }
    
    h3 {
        font-size: 1.1em;
    }
    
    .mw-path-img {
        width: 168px;
    }
    
    .modal-body .mw-img.mw-top {
        width: 250px;
    }
    
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) { 

    
    #frontpage-login-link {
        font-size: 1em;
    }
    
    .jumbotron h2 {
        font-size: 2em;
    }
    
    .modal-dialog {
        margin-bottom: 60px;
    }
    
    .navbar-toggle {
        display: block;
    }
    
    #mobile-toggle {
        display: none;
    }
        
    #mw-menubar {
        display: none;
    }
    
    #mobile-points {
        display: none;
    }
            
    .mw-view-selection, .mw-contents-banner {
        margin-top: -4.5em;
        padding-top: 1em;
        
        &.well {
            padding-left: 12px;
            padding-right: 12px;
        }
        
        h4 {
            font-size: 1.25em;
        }
        
        .btn {
            font-size: 1.25em;
        }
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) { 
        
    
    .mw-view-selection, .mw-contents-banner {
        padding-bottom: 0.5em;
    }
    
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) { 

    
    
}



/*==========  Non-Mobile First Method  ==========*/


/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

    .navbar-container {
        width: auto;
    }
    
}

/* Small Devices, Tablets - 768px and down */
@media only screen and (max-width : 768px) {
    
        
    .navbar-fixed-top, .navbar-fixed-bottom {
        position: static;
    }
    
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

    .jumbotron h2 {
        font-size: 1.7em;
    }
    
    .navbar-brand {
        width: 8em;
        margin-right: 0;
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
    
}