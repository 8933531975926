@import "solarized";

.dropzone {
    min-height: 8em;    
    border: 3px dashed rgba(0,0,0,0.2);
    background: #f0f0f0;
    padding: 1em 1em;
    
    > .dz-message {
        text-align: center;
        margin: 0.8em 0;
        font-size: 1.2em;
        color: @gray-dark;
        
        > span > .fa {
            display: block;
            font-size: 2em;
            margin-bottom: 0.5em;
            color: @gray-dark;
        }
    }
    
    &.dz-drag-hover {
        border-style: solid;
        border-color: @base02;
        background: @base01;
        
        > .dz-message {
            opacity: 0.5; 
        }
    }
}

