/* WAS: rebase03*/
.dropzone {
  min-height: 8em;
  border: 3px dashed rgba(0, 0, 0, 0.2);
  background: #f0f0f0;
  padding: 1em 1em;
}
.dropzone > .dz-message {
  text-align: center;
  margin: 0.8em 0;
  font-size: 1.2em;
  color: #aaaaaa;
}
.dropzone > .dz-message > span > .fa {
  display: block;
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #aaaaaa;
}
.dropzone.dz-drag-hover {
  border-style: solid;
  border-color: #00aeef;
  background: #9fdbed;
}
.dropzone.dz-drag-hover > .dz-message {
  opacity: 0.5;
}
