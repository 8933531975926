@import "../../../bower_components/font-awesome/less/font-awesome.less";
@import "../../../bower_components/bootstrap/less/bootstrap.less"; 
@import "../../../bower_components/bootstrap-select/less/bootstrap-select.less";
@import "../../../bower_components/bootstrap-social/bootstrap-social.less";



.panel-heading h3 {
   margin: 0;
}

    .panel-success .panel-heading h3 {
        color: #fff;
    }

a.btn {
    text-decoration: none;
    border: none;
}

a.list-group-item {
    text-decoration: none;
}

.sp-counters-panel {
    padding-bottom: 12px;
}

.sp-counter {
    font-size: 60px; 
    text-align: center;
}

.sp-counter-index {
    float: left;
    width: 12.5%;
    color: #777;
    font-size: 13px;
    text-align: center;
    
    .sp-counter-legend {
        display: block;
        padding-top: 4px;
    }
}


.sp-counter-cell {
    float: left;
    width: 12.5%;
    padding: 0 0.2em;
    text-align: center;
    
    .sp-counter {
        font-size: 26px;
        margin-bottom: 5px;
        
        a, .text-muted {
            border: none;
            display: block;
            margin-bottom: 0;
            line-height: 1;
        }
        
        a:hover {
            font-weight: bold;
            color: #000;
        }
    }
    
    .label {
        display: inline;
    }
}

#todo-toggle label {
    font-size: 0.93em;
}

#category-filter label {
    font-size: 0.85em;
}

.threat-reports-count {
    margin-top: 2px;
}


canvas{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    padding: 4px;
}

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
}



.sp-contents-banner {
    position: relative;
    z-index: 1;
    margin: -3em 0 1em 0;
    padding: 1.2em 1em 0.6em 1em;
    min-height: 20px;
    background-color: #f8f8f8;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    
    h4 {
        font-size: 1.1em;
        text-align: center;
    }    
}

.sp-video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
    
    > iframe, > object, > embed {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}


.sp-agent {
    width: 120px;
    margin-bottom: 1.5em;
    margin-top: 0.4em;
}

.sp-bubble {
    min-width: 220px;
    position: relative;
    left: -1px;
    display: inline-block;
    min-height: 1.5em;
    padding: 2em 1.5em 1.5em 1.5em;
    background-color: #fbf6d9;
    border: #e6db98 solid 2px;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    border-radius: 1em;
    margin: 0 3.5em 2em 1em;
}
    
    .sp-bubble:after {
        content: " ";
        position: absolute;
        top: 26px;
        left: -17px;
        border-style: solid;
        border-width: 17px 17px 0;
        border-color: #e6db98 transparent;
        border-top: 17px solid transparent;
        border-right: 17px solid #e6db98;
        border-left: 0 solid #e6db98;
        border-bottom: 17px solid transparent;
        display: inline-block;
        width: 0;
        z-index: 1;
    }
    
    .sp-bubble:before {
        content: " ";
        position: absolute;
        top: 28px;
        left: -15px;
        border-style: solid;
        border-width: 15px 15px 0;
        border-color: #FBF6D9 transparent;
        border-top: 15px solid transparent;
        border-right: 15px solid #FBF6D9;
        border-left: 0 solid #FBF6D9;
        border-bottom: 15px solid transparent;
        display: inline-block;
        width: 0;
        z-index: 2;
    }

    
    

.sp-flash {
    margin-top: -4.15em;
    text-align: center;
    
    &.alert {
        border-left-width: 0;
        border-left-style: none;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        padding-left: 0.7em;
    }
}
    

.flag-icon-disabled {
    opacity: 0.6;
    filter: grayscale( 100% );
    -webkit-filter: grayscale( 1 );
}





.panel {
    width: 100%;
    border: 1px solid #d4d4d4;
    background-color: #ffffff;
    position: relative;
    border-radius: 4px; 
    padding: 20px;
    padding-bottom: 10px; 
    margin-bottom: 2em;
    box-shadow: none;
    
    .panel-title {
        margin-top: 0;
        color: inherit;
    }
    
    .panel-heading {
        background-color: #fff; 
        padding: 0;
        margin-bottom: 15px;
             
        > h3 {
            color: #000;
            margin-top: 0;
        }   
    }
    
    .panel-body {
        padding: 0;
        
        > ul {
            padding: 10px 15px;
            margin-bottom: 0;
        }
        
        > p + p {
            margin-top: 5px;
        }
        
        > div > strong > a {
            text-decoration: none;
        }
        
        > .panel-module {
            clear: both;
            margin: 15px 15px;
            margin-top: 0;
        }
    }
    
    
    &.padded {
        padding: 2em;
    }
}


.table td, table th {
    word-break: break-word; 
}

    


#content {
    padding-top: 2.5em;
    clear: both;
    margin: 0 auto;
}

    .sp-has-flash #content {
        padding-top: 0;
    }

    .sp-employee.sp-auth #content {
        padding-top: 8em;
    }
    

.not-registered-link {
    margin-top: 2em;
    color: #555555;
}
    

.sp-path-img {
    width: 100%;
}

.modal-body .sp-img.sp-top {
    width: 100%;
}

.sp-right-column-heading {
    margin-top: 2.7em;
}


a.label {
    border: none;
}


.label {

    &.label-white {
        background-color: #f0f0f0;
        color: #000;
    }
    
    &.label-black {
        background-color: #000;
        color: #FFFFFF;
    }
}